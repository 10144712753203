
@mixin main-width {
  width: 80rem;
  margin: auto;
}

:root {
  --content-padding-x: 1rem;
  --content-padding-y: 0.2rem;
  --title-color: #023059;
  --title-color-light: #3f7ea6;
  --title-color-inter: #a9a9a9;
  --border-color: #df5a00;
}

html, body {
print-color-adjust: exact;
height: 97.5%;
width: 96%;
  margin: 1% 2%;
  margin-top: 1.5%;
  box-sizing: border-box;
  font-weight: 200;
}

p.intro {
  @include main-width();
  box-sizing: border-box;
  position: relative;
  background-color: white;
  font-size: 0.8rem;
  line-height: 1.1rem;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  padding: 0.8rem 1rem;
  border-radius: 0.2rem;
  font-weight: 200;
  text-align: justify;
  margin-bottom: 1.5rem;
}

body {  
  font-family: "Roboto Slab", sans-serif;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

h1, h2, h3 {
  font-family: "Saira Condensed";
  font-weight: 500;
  text-transform: uppercase;
}

h1 {
  color: var(--title-color);
  font-size: 2.8rem;
  margin: 0;
  line-height: 3rem;
}

h2 {
  color: var(--title-color);
  margin-top: 0rem;
  font-size: 1.4rem;
  border-bottom: 1px solid var(--border-color);
}

h3 {
  color: var(--title-color-light);
  font-weight: 300;
}

b {
  font-weight: 400;
}

.content {
  @include main-width();
  box-sizing: border-box;
  
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-between;

}

section {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  padding: var(--content-padding-y) var(--content-padding-x) ;
  border-radius: 0.2rem;
  margin-bottom: 0.6rem;
  /*box-shadow: 0px 0px 4px 1px rgba(31,132,189,0.17);*/
  /*border-bottom: 1px solid var(--title-color-light);*/
  /*border-left: 1px solid var(--title-color-light);*/
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}

.experience {
  width: 100%;

  .job {
      width: 100%;
      gap: 1rem;
      display: flex;
      h3 {
          font-size: 0.8rem;
          width: 24%;
          margin-top: 0;
      }

      &.single-line {
          h3 {
              margin-bottom: 0;
          }
      }

      .job-desc {
          width: 75%;
          .company-name {
              color: var(--title-color);
              font-family: "Saira Condensed";
              font-weight: 400;
              text-transform: uppercase;
              font-size: 0.8rem;
          }
          .role {
              display: inline-block;
              margin-left: 0rem;
              &::before{
                  content: '- ';
              }
              font-size: 0.8rem;
              text-transform: uppercase;
              font-family: "Saira Condensed";
              font-weight: 300;
          }
          p {
              margin-top: 0rem;
              margin-bottom: 0;
              font-size: 0.8rem;
          }
      }
      
      flex-flow: row;
      margin-bottom: 0.7rem;
      
  }



}

header {
  @include main-width();
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  a {
    text-decoration: none;
  }
}

ul {

  padding-left: 1rem;
}

.year {
  font-weight: 400;
}

section.competences {
  width: 48%;
}

section.formation {
  width: 48%;
}

.coords {
  display: inline-block;

  justify-content: center;
  text-transform: uppercase;
  color: var(--title-color-light);
  a {
      color: var(--title-color-light);
  }
}

.subtitle {
  text-align: right;
  color: var(--title-color-light);
  display: flex;
  font-weight: 300;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  justify-content: space-around;
}

.coords-row {
  display: flex;
  flex-direction: row-reverse;
}

.secondary {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@page { size: a4 portrait;  margin: 0mm;}

@media print {
  a {
      text-decoration: none;
  }
}

.ray {
  position: absolute;
  height: 120%;
  width: 0.1rem;
  z-index: 0;
  top:-5rem;
  left: 30rem; 
  transform: rotate(-3deg);
  background-color: var(--border-color);
}

footer {

  display: none;
}

@media screen {
  body, html {min-height: 100vh;}
  body {
    height: auto;
  }
  body {
    position: relative;
    padding-bottom: 1rem;
  }
  header {
      position: sticky;
      top: 0;
      z-index: 4;
      background-color:#f5f9fb;
      
      /*box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;*/
  }

  footer {
    @include main-width();
    display: block;
    position: absolute;
    bottom: 0;

    background-color: white;
    box-sizing: border-box;
    padding: var(--content-padding-y) var(--content-padding-x) ;
    border-radius: 0.2rem;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    margin-bottom: 1rem;
  }

  body {

      color: #202020;
      font-size: 1rem;
      /*background-color: #efefef;*/
      background-color:#f5f9fb;
     padding-bottom: 3rem;
      width: 100%;
      margin: 0;
  }

  p.intro {
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.4rem;
  }
  .experience {
      .job {
          h3 {
              font-size: 1.2rem;
          }
          margin-top: 1.4rem;
          .job-desc {
              font-weight: 300;

              .company-name {
                  font-size: 1.2rem;
              }
              .role {
                  font-size: 1.2rem;
              }
              p {
                  font-size: 1rem;
                  line-height: 1.4rem;
              }
          }
      }
  }

  .content {
      flex-flow: row;
      .experience {
          width: 100%;
      }
      .secondary {
          flex-direction: column;
          section {
              width: 100%;
          }
      }
  }
}

@media screen and (max-width: 80rem) {

  .content {
      flex-flow: column;
      .secondary {
          flex-direction: row;
      }
  }

 
}

@media screen and (max-width: 40rem) {

  body {

    h1 {
      font-size: 1.8rem;
      line-height: 2rem;
    }
    .subtitle {
      font-size: 0.9rem;
    }
    padding-bottom: 4.2rem;
    .content {
      .secondary {
        flex-direction: column;
      }
    }

  }

 
}


.material-symbols-outlined {
vertical-align: sub;
  font-size: 1rem;
font-variation-settings:
'FILL' 0,
'wght' 400,
'GRAD' 0,
'opsz' 24
}



.material-symbols-outlined {
  vertical-align: sub;
  font-size: 1rem;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
