:root {
  --content-padding-x: 1rem;
  --content-padding-y: .2rem;
  --title-color: #023059;
  --title-color-light: #3f7ea6;
  --title-color-inter: #a9a9a9;
  --border-color: #df5a00;
}

html, body {
  print-color-adjust: exact;
  height: 97.5%;
  width: 96%;
  box-sizing: border-box;
  margin: 1.5% 2% 1%;
  font-weight: 200;
}

p.intro {
  text-align: justify;
  background-color: #fff;
  border-radius: .2rem;
  margin-bottom: 1.5rem;
  padding: .8rem 1rem;
  font-size: .8rem;
  font-weight: 200;
  line-height: 1.1rem;
  position: relative;
  box-shadow: 0 0 0 1px #0e3f7e0a, 0 1px 1px -.5px #2a33450a, 0 3px 3px -1.5px #2a33460a, 0 6px 6px -3px #2a33460a, 0 12px 12px -6px #0e3f7e0a, 0 24px 24px -12px #0e3f7e0a;
}

body {
  flex-direction: column;
  gap: 0;
  font-family: Roboto Slab, sans-serif;
  font-size: .75rem;
  display: flex;
}

h1, h2, h3 {
  text-transform: uppercase;
  font-family: Saira Condensed;
  font-weight: 500;
}

h1 {
  color: var(--title-color);
  margin: 0;
  font-size: 2.8rem;
  line-height: 3rem;
}

h2 {
  color: var(--title-color);
  border-bottom: 1px solid var(--border-color);
  margin-top: 0;
  font-size: 1.4rem;
}

h3 {
  color: var(--title-color-light);
  font-weight: 300;
}

b {
  font-weight: 400;
}

.content {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

section {
  box-sizing: border-box;
  padding: var(--content-padding-y) var(--content-padding-x);
  background-color: #fff;
  border-radius: .2rem;
  margin-bottom: .6rem;
  position: relative;
  box-shadow: 0 0 0 1px #0e3f7e0a, 0 1px 1px -.5px #2a33450a, 0 3px 3px -1.5px #2a33460a, 0 6px 6px -3px #2a33460a, 0 12px 12px -6px #0e3f7e0a, 0 24px 24px -12px #0e3f7e0a;
}

.experience {
  width: 100%;
}

.experience .job {
  width: 100%;
  flex-flow: row;
  gap: 1rem;
  margin-bottom: .7rem;
  display: flex;
}

.experience .job h3 {
  width: 24%;
  margin-top: 0;
  font-size: .8rem;
}

.experience .job.single-line h3 {
  margin-bottom: 0;
}

.experience .job .job-desc {
  width: 75%;
}

.experience .job .job-desc .company-name {
  color: var(--title-color);
  text-transform: uppercase;
  font-family: Saira Condensed;
  font-size: .8rem;
  font-weight: 400;
}

.experience .job .job-desc .role {
  text-transform: uppercase;
  margin-left: 0;
  font-family: Saira Condensed;
  font-size: .8rem;
  font-weight: 300;
  display: inline-block;
}

.experience .job .job-desc .role:before {
  content: "- ";
}

.experience .job .job-desc p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: .8rem;
}

header {
  justify-content: space-between;
  margin-bottom: 1.2rem;
  display: flex;
  position: relative;
}

header a {
  text-decoration: none;
}

ul {
  padding-left: 1rem;
}

.year {
  font-weight: 400;
}

section.competences, section.formation {
  width: 48%;
}

.coords {
  text-transform: uppercase;
  color: var(--title-color-light);
  justify-content: center;
  display: inline-block;
}

.coords a {
  color: var(--title-color-light);
}

.subtitle {
  text-align: right;
  color: var(--title-color-light);
  text-transform: uppercase;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 300;
  display: flex;
}

.coords-row {
  flex-direction: row-reverse;
  display: flex;
}

.secondary {
  flex-direction: row;
  gap: 1rem;
  display: flex;
}

@page {
  size: a4 portrait;
  margin: 0;
}

@media print {
  a {
    text-decoration: none;
  }
}

.ray {
  height: 120%;
  width: .1rem;
  z-index: 0;
  background-color: var(--border-color);
  position: absolute;
  top: -5rem;
  left: 30rem;
  transform: rotate(-3deg);
}

footer {
  display: none;
}

@media screen {
  body, html {
    min-height: 100vh;
  }

  body {
    height: auto;
    padding-bottom: 1rem;
    position: relative;
  }

  header {
    z-index: 4;
    background-color: #f5f9fb;
    position: sticky;
    top: 0;
  }

  footer {
    width: 100%;
    box-sizing: border-box;
    padding: var(--content-padding-y) var(--content-padding-x);
    background-color: #fff;
    border-radius: .2rem;
    margin-bottom: 1rem;
    display: block;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 0 1px #0e3f7e0a, 0 1px 1px -.5px #2a33450a, 0 3px 3px -1.5px #2a33460a, 0 6px 6px -3px #2a33460a, 0 12px 12px -6px #0e3f7e0a, 0 24px 24px -12px #0e3f7e0a;
  }

  body {
    color: #202020;
    width: 100%;
    background-color: #f5f9fb;
    margin: 0;
    padding-bottom: 3rem;
    font-size: 1rem;
  }

  p.intro {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4rem;
  }

  .experience .job {
    margin-top: 1.4rem;
  }

  .experience .job h3 {
    font-size: 1.2rem;
  }

  .experience .job .job-desc {
    font-weight: 300;
  }

  .experience .job .job-desc .company-name, .experience .job .job-desc .role {
    font-size: 1.2rem;
  }

  .experience .job .job-desc p {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .content {
    flex-flow: row;
  }

  .content .experience {
    width: 100%;
  }

  .content .secondary {
    flex-direction: column;
  }

  .content .secondary section {
    width: 100%;
  }
}

@media screen and (max-width: 80rem) {
  .content {
    flex-flow: column;
  }

  .content .secondary {
    flex-direction: row;
  }
}

@media screen and (max-width: 40rem) {
  body {
    padding-bottom: 4.2rem;
  }

  body h1 {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  body .subtitle {
    font-size: .9rem;
  }

  body .content .secondary {
    flex-direction: column;
  }
}

.material-symbols-outlined {
  vertical-align: sub;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  vertical-align: sub;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  font-size: 1rem;
}

/*# sourceMappingURL=main.css.map */
